//src/api/brandapi.js
import request from '@/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */
/*反馈接口---------------------------*/
//获取反馈列表接口
export function getFeedData(obj) {
    return request({
        url: '/api/admin/feedbacktype?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增反馈接口
export function postaddFeed(obj,url) {
    return request({
        url: '/api/admin/feedbacktype/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑反馈接口
export function posteditFeed(obj,url) {
    return request({
        url: '/api/admin/feedbacktype/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除反馈接口
export function postdelFeed(obj,url) {
    return request({
        url: '/api/admin/feedbacktype/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
/*投诉接口---------------------------*/
//获取投诉列表接口
export function getCompData(obj) {
    return request({
        url: '/api/admin/complaintype?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增投诉接口
export function postaddComp(obj,url) {
    return request({
        url: '/api/admin/complaintype/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑投诉接口
export function posteditComp(obj,url) {
    return request({
        url: '/api/admin/complaintype/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除投诉接口
export function postdelComp(obj,url) {
    return request({
        url: '/api/admin/complaintype/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


/*退款类型接口---------------------------*/
//获取退款类型列表接口
export function getRefTypeData(obj,val) {
    return request({
        url: '/api/admin/refundtype?w_type='+val,      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增退款类型接口
export function postaddRefType(obj,url) {
    return request({
        url: '/api/admin/refundtype/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑退款类型接口
export function posteditRefType(obj,url) {
    return request({
        url: '/api/admin/refundtype/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除退款类型接口
export function postdelRefType(obj,url) {
    return request({
        url: '/api/admin/refundtype/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


/*投诉接口---------------------------*/
//获取投诉列表接口
export function getRefreasonData(obj) {
    return request({
        url: '/api/admin/refundreason?w_type=all',      // url = base url + request url
        method: 'get',
        params: obj,
        //----------------->>>区别
    })
}
//新增投诉接口
export function postaddRefreason(obj,url) {
    return request({
        url: '/api/admin/refundreason/add',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//编辑投诉接口
export function posteditRefreason(obj,url) {
    return request({
        url: '/api/admin/refundreason/edit',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}
//删除投诉接口
export function postdelRefreason(obj,url) {
    return request({
        url: '/api/admin/refundreason/del',      // url = base url + request url
        method: 'post',
        data: obj,
        //----------------->>>区别
    })
}


