<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)" :data="tableData" style="width: 100%;">
      <el-table-column  align="center"
                        type="index"
                         width="55" label="#" >
      </el-table-column>
        <el-table-column prop="name" label="投诉类型名称" >
        </el-table-column>
        <el-table-column prop="tip" label="提示" >
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" >
        </el-table-column>
        <el-table-column prop="address" label="操作" width="110">
          <template #header>
            <el-button size="mini"  @click="add()" type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>

          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>

      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item label="类型名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="平台" prop="tip">
          <el-input v-model="ruleForm.tip"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/feedApi"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        tip:'',
      },
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'blue' },
        ],
        tip: [
          { required: true, message: '请填写提示', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.keyupSubmit()
  },

  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getCompData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        tip:'',
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.tip=row.tip
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login=true
          if(val==1){
            axios.postaddComp(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditComp(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelComp({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>